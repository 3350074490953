import axios from 'axios';
import qs from 'qs';
// import store from '../store';
import router from '@/router';
// import { ElMessage , ElLoading } from 'element-plus';
import { Loading, Message } from 'element-ui';

// axios.defaults.baseURL = '/'  //正式
// axios.defaults.baseURL = 'http://192.168.11.46:82/' //测试
// axios.defaults.baseURL = process.env.NODE_ENV == 'production' ? '/' : '/api'

//post请求头
// axios.defaults.headers.post["Content-Type"] ="application/x-www-form-urlencoded;charset=UTF-8";
//设置超时
// axios.defaults.timeout = 10000;

let token = window.sessionStorage.getItem('token'); //获取token

// axios.interceptors.request.use(
//     config => {
//         return config;
//     },
//     error => {
//         return Promise.reject(error);
//     }
// );

// axios.interceptors.response.use(
//     response => {
//         if (response.status == 200) {
//             return Promise.resolve(response);
//         } else {
//             return Promise.reject(response);
//         }
//     },
//     error => {
//         return Promise.reject(error);
//     }
// );
export default {
  post(url, data, DOM = '', config = '') {
    let setting = {};
    if (DOM) {
      // do nothing
    }
    // let loadingInstance = '';
    // if (DOM) {
    //     if (DOM != 'false') {
    //         loadingInstance = Loading.service({
    //             target: document.querySelector('.' + DOM)
    //         });
    //     }
    // } else {
    //     loadingInstance = Loading.service({ fullscreen: true });
    // }
    if (config) {
      setting = {
        token: token,
        'Content-Type': config,
      };
    } else {
      //设置请求头信息
      setting = {
        token: token,
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      };
    }
    return new Promise((resolve, reject) => {
      axios({
        method: 'post',
        url,
        data: config ? data : qs.stringify(data),
        headers: setting,
      })
        .then((res) => {
          // if (DOM != 'false') {
          //     loadingInstance.close();
          // }
          if (this.close(res)) {
            resolve(res.data);
          }
        })
        .catch(async (err) => {
          // if (DOM != 'false') {
          //     loadingInstance.close();
          // }
          let data = JSON.parse(await err.response.data.text());
          console.error(data);
          // Message.error(JSON.stringify(err.message));
          Message.error(data.msg);
          reject(err);
        });
    });
  },

  get(url, data, DOM = '', loading = '') {
    if (DOM && loading) {
      // do nothing
    }
    //loading信息 实际体验like piece of a shit 😅
    // let loadingInstance = '';
    // if (loading == '') {
    //     if (DOM) {
    //         loadingInstance = Loading.service({
    //             target: document.querySelector('.' + DOM)
    //         });
    //     } else {
    //         loadingInstance = Loading.service({ fullscreen: true });
    //     }
    // }
    let setting = {
      token: token,
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    };
    return new Promise((resolve, reject) => {
      axios({
        method: 'get',
        url,
        params: data,
        headers: setting,
      })
        .then((res) => {
          // if (loading == '') {
          //     loadingInstance.close();
          // }
          if (this.close(res)) {
            resolve(res.data);
          }
        })
        .catch(async (err) => {
          let data = JSON.parse(await err.response.data.text());
          console.error(data);
          // Message.error(JSON.stringify(err.message));
          Message.error(data.msg);
          reject(err);
        });
    });
  },

  exports(url, data, DOM = '', name = '') {
    let loadingInstance = '';
    if (DOM) {
      loadingInstance = Loading.service({
        target: document.querySelector('.' + DOM),
      });
    } else {
      loadingInstance = Loading.service({ fullscreen: true });
    }
    let setting = {
      token: token,
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    };
    return new Promise((resolve, reject) => {
      axios({
        method: 'post',
        url: url,
        params: data,
        headers: setting,
        responseType: 'blob',
      })
        .then(async (res) => {
          if (res.status == 200) {
            var blob = new Blob([res.data]);
            var filename = name;
            var a = document.createElement('a');
            var url = window.URL.createObjectURL(blob);
            a.href = url;
            a.download = filename;
            var body = document.getElementsByTagName('body')[0];
            body.appendChild(a);
            a.click();
            body.removeChild(a);
            window.URL.revokeObjectURL(url);
            loadingInstance.close();
            resolve(res.data);
          } else {
            loadingInstance.close();

            let resData = JSON.parse(await res.data.text());

            Message.error(resData.msg);
          }
        })
        .catch(async (err) => {
          loadingInstance.close();

          let data = JSON.parse(await err.response.data.text());
          console.error(data);
          // Message.error(JSON.stringify(err.message));
          Message.error(data.msg);
          reject(err);
        });
    });
  },

  close(res) {
    if (res.data.code === 1001) {
      Message.error(res.data.msg);
      // store.dispatch('user/setToken', null);
      window.sessionStorage.clear();
      router.push({ path: '/login' });
      return false;
    }
    if (res.data.code == 500) {
      Message.error(res.data.msg);
      window.sessionStorage.clear();
      router.push({ path: '/login' });
      return false;
    }
    if (res.data.code == 444) {
      Message.error(res.data.msg);
      window.sessionStorage.clear();
      window.location.href = '/';
    }
    if (res.data.code == 10012) {
      return true;
    }
    if (res.data.code == 1005) {
      return true;
    }
    if (res.data.code == 1002) {
      return true;
    }
    if (res.data.code !== 200) {
      Message.error(res.data.msg);
      return false;
    }
    // console.log(res.data);
    return true;
  },

  exportsTxt(url, data, DOM, name) {
    let loadingInstance = '';
    if (DOM) {
      loadingInstance = Loading.service({
        target: document.querySelector('.' + DOM),
      });
    } else {
      loadingInstance = Loading.service({ fullscreen: true });
    }
    let setting = {
      token: token,
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    };
    return new Promise((resolve, reject) => {
      axios({
        method: 'get',
        url: url,
        params: data,
        headers: setting,
      })
        .then((res) => {
          var filename = '';
          if (name != '') {
            filename = name;
          } else {
            filename = res.data.result.filename;
          }
          const element = document.createElement('a');
          element.setAttribute(
            'href',
            'data:text/plain;charset=utf-8,' + encodeURIComponent(res.data.result.jsondata),
          );
          element.setAttribute('download', filename + '.json');
          element.style.display = 'none';
          element.click();
          loadingInstance.close();
          resolve(res.data);
        })
        .catch((err) => {
          loadingInstance.close();
          Message.error(JSON.stringify(err.message));
          reject(err);
        });
    });
  },
};
